.container {
  padding: 10px 30px;
  max-width: 1200px;
  margin: 0 auto;
}

.card-wrapper {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  gap: 50px;
  align-items: center;
}

.balance-card,
.stream-card {
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 16px;
  border: 1px solid rgba(255, 255, 255, 0.6);
  background-color: #0e111b;
  color: white;
}

.balance-card {
  width: 300px;
  height: 365px;
  margin-top: 20px;
}

.stream-card {
  width: 320px;
  height: 150px;
  margin-top: 20px;
}

@media (min-width: 768px) {
  .wallet-amount-card {
    width: 100%; /* Full width for medium and larger screens */
  }
}

.stream-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns in the stream section */
  gap: 40px;
}

/* Responsive Design */

@media (max-width: 1024px) {
  .stream-card {
    width: 200px;
    height: 150px;
    margin-top: 20px;
  }

  .balance-card {
    width: 260px;
    height: 310px;
    margin-top: 20px;
  }

  .card-wrapper {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 768px) {
  .stream-card {
    width: 300px;
    height: 150px;
    margin-top: 0;
  }

  .stream-column {
    margin: 0 auto;
  }

  .stream-wrapper {
    grid-template-columns: repeat(1, 1fr);
    gap: 0;
    margin: 20px auto;
  }

  .card-wrapper {
    display: block;
    justify-content: center;
    flex-wrap: nowrap;
    align-items: center;
    margin: 20px auto;
  }

  .balance-card {
    width: 300px;
    margin: 20px auto;
    height: 150px;
  }
}
